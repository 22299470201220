var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"router"},[_c('div',{staticClass:"router-info"},[_c('div',{staticClass:"router-info__create"},[_c('base-button',{staticClass:"stack-info__create-btn",attrs:{"icon":_vm.isIcon,"tooltip":{
          content: _vm.text,
          autoHide: false,
          placement: 'auto',
          container: false,
          trigger: 'click hover',
        },"color":_vm.isColor},on:{"click":function($event){_vm.isFirewalls !== 0 ? _vm.addRule(_vm.tariff) : ''}}},[_vm._v(_vm._s(_vm.$t('addSubnet')))])],1)]),_c('transition',{attrs:{"name":"bubble"}},[_c('page-block',{staticClass:"table"},[_c('tariffs-table-rules-view',{attrs:{"dataset":_vm.rules,"firewall":_vm.firewallId}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }