<template>
  <div class="router">
    <div class="router-info">
      <div class="router-info__create">
        <!--        <base-button-->
        <!--          :disabled="isFirewalls === 0"-->
        <!--          class="router-info__create-btn"-->
        <!--          @click="addRule(tariff)"-->
        <!--        >-->
        <!--          {{ $t('addSubnet') }}-->
        <!--        </base-button>-->
        <base-button
          :icon="isIcon"
          :tooltip="{
            content: text,
            autoHide: false,
            placement: 'auto',
            container: false,
            trigger: 'click hover',
          }"
          :color="isColor"
          class="stack-info__create-btn"
          @click="isFirewalls !== 0 ? addRule(tariff) : ''"
          >{{ $t('addSubnet') }}</base-button
        >
      </div>
    </div>
    <transition name="bubble">
      <page-block class="table">
        <tariffs-table-rules-view :dataset="rules" :firewall="firewallId" />
      </page-block>
    </transition>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import TariffsTableRulesView from '@/layouts/Stack/pages/Main/components/TariffsTableRulesView';
import addRule from '@/mixins/addRule';
export default {
  name: 'FirewallView',
  components: {
    TariffsTableRulesView,
  },
  mixins: [providerChange, addRule],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      // isLoading: true,
    };
  },
  computed: {
    isIcon() {
      return this.isFirewalls === 0 ? 'error' : '';
    },
    isColor() {
      return this.isFirewalls === 0 ? 'warn' : 'primary';
    },
    text() {
      if (this.isFirewalls === 0) {
        return 'Вы достигли квоты по количеству правил,<br />обратитесь в поддержку для изменения квоты';
      } else {
        return 'Создать новое правило';
      }
    },
    firewallId() {
      return this.tariff.id;
    },
    rules() {
      return this.$store.getters['moduleStack/GET_SORTED_LIST_RULES'].filter(
        x => x.security_group_id === this.tariff.id
      );
    },
    isFirewalls() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.security_group_rule
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.security_group_rule.limit -
          this.$store.state.moduleStack.quotasNetwork.security_group_rule.used
        );
      } else return 0;
    },
  },
  mounted() {
    // this.getPort(this.tariff.id);
  },
  methods: {
    // getPort(id) {
    //   return this.$store.dispatch('moduleStack/getPort', id);
    // },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addSubnet": "Добавить правило",
      "sure": {
        "confirm": "Создать правило",
        "success": "Правило добавлено успешно",
        "quotaFirewall": "Вы достигли квоты по количеству правил, обратитесь в поддержку для изменения квоты",
        "fail": "Что-то пошло не так",
        "close": "Хорошо"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.table {
  margin-top: 0.5rem;
}
.router {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__create {
      display: flex;
      flex-direction row;
      align-content flex-end;
      align-items baseline;
      margin-top: 1.5rem;
      max-width: 200px;
      &-btn {
        margin-top: 20px
        margin-right: 0;
        +breakpoint(ms-and-up) {
          margin-top: 20px;
        }
      }
      &-hint {
        margin: -0.25rem 0.25rem;
        vertical-align: middle;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

}
</style>
