import AddRule from '@/layouts/Stack/components/AddRule';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';

export default {
  mixins: [showErrorModal, storeMixin],
  data() {
    return {
      device_id: '',
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    addRule: function (instance) {
      const that = this;
      let self;
      let time;
      let res = '';
      return new Promise(() => {
        const selfName = 'addRule';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AddRule,
          closeOnBackdrop: false,
          props: {
            router: instance.id,
            instance: instance,
          },
          on: {
            change: data => {
              instance = data;
              Vue.set(self.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', true);
            },
            ready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen(inst) {
            self = inst;
          },
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  self.text = null;
                  let mode;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  time = Date.now();
                  this.isProcessing = true;

                  this.addNewRule(instance)
                    .then(async data => {
                      if (data && data.security_group_rule) {
                        await this.$store.dispatch(
                          'moduleStack/fetchOpenStackRequestRules',
                          'rules'
                        );
                        res = 'success';
                      } else {
                        res = 'fail';
                      }
                    })
                    .catch(() => {
                      res = 'fail';
                      mode = res;
                      this.$modals.close();
                    })
                    .finally(() => {
                      const delay = Date.now() - time < 1000 ? 1000 : 0;
                      setTimeout(() => {
                        self.component = null;
                        self.closable = true;
                        self.text = this.$t(`sure.${res}`);
                        self.footer = {
                          centered: true,
                          confirm: {
                            props: { title: this.$t('sure.close') },
                            on: { click: () => this.$modals.close() },
                          },
                        };
                      }, delay);
                    });
                },
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        });
      });
    },
    addNewRule(payload) {
      return this.$store.dispatch('moduleStack/createRule', payload).catch(e => {
        console.log(e);
        this.showError(e);
      });
    },
    addRouterInterface(port, router) {
      const params = {
        port_id: port,
        router_id: router,
      };
      return this.$store.dispatch('moduleStack/addRouterInterface', params).catch(e => {
        console.log(e);
        this.showError(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        confirm: {
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
