<template>
  <div class="stack-context-menu">
    <template v-if="isSuspended">
      <div class="stack-context-menu__noact standart-text">
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
    <template v-else>
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.handler}`) }}
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import renameFirewall from '@/mixins/renameFirewall';
export default {
  name: 'RuleMemberContextMenu',
  mixins: [renameFirewall, showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    rule: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    isSuspended() {
      // if (this.tariff.provisioning_status)
      return (
        this.tariff.provisioning_status === 'PENDING_DELETE' ||
        this.tariff.provisioning_status === 'PENDING_CREATE'
      );
    },
    nameServer() {
      return this.tariff.name;
    },
    nameRule() {
      return this.$store.state.moduleStack.pools.find(x => x.id === this.rule).name;
    },
    menu() {
      const arr = [];
      if (this.tariff.name !== 'default')
        arr.push([
          {
            key: this.tariff.backup ? 'unreserveT' : 'reserveT',
            id: this.tariff.id,
            handler: this.tariff.backup ? 'unreserve' : 'reserve',
            label: this.tariff.backup ? 'unreserve' : 'reserve',
            action: {
              member: {
                backup: !this.tariff.backup,
              },
            },
          },
          {
            key: this.tariff.weight === 0 ? 'unpauseT' : 'pauseT',
            id: this.id,
            handler: this.tariff.weight === 0 ? 'unpause' : 'pause',
            label: this.tariff.weight === 0 ? 'unpause' : 'pause',
            action: {
              member: {
                weight: this.tariff.weight === 0 ? '1' : '0',
              },
            },
            // disabled: this.tariff.weight === 0,
          },
          {
            key: 'delete',
            id: this.id,
            color: 'del',
            // disabled: this.tariff.name === 'default',
            handler: 'delete',
            action: 'delete',
          },
        ]);
      return arr;
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'delete') this.deleteMember(item);
      if (item.action && item.handler === 'pause') this.askSure(item);
      if (item.action && item.handler === 'unpause') this.askSure(item);
      if (item.action && item.handler === 'reserve') this.askSure(item);
      if (item.action && item.handler === 'unreserve') this.askSure(item);
      // if (item.action && item.key === 'edit') this.deleteMember(this.tariff.name, item);
    },
    deleteMember(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        // text: this.$t('delete') + this.tariff.name + '?',
        html: true,
        text: this.$t(`modal.sure.${action.handler}`, {
          msg: this.nameServer,
          pool: this.nameRule,
        }),
        // text: this.$t('modal.sure.text', {
        //   msg: `удалить ${this.name}`,
        // cost: this.$n(
        //     this.cost(`${action.key}_${this.tariff.volume_type}`),
        //     'currency',
        //     this.$i18n.locale
        // ),
        // }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentMember(this.rule, this.tariff.id).then(async data => {
                  console.log(data);
                  this.$store.commit('moduleStack/updateRuleMember', {
                    ruleId: this.rule,
                    memberId: this.tariff.id,
                    delete: true,
                  });
                  await this.fetchMembers();
                  // await this.$store.dispatch(
                  //   'moduleStack/fetchOpenStackRequestFirewalls',
                  //   'firewalls'
                  // );
                });
                res = 'success';
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  self.text = this.$t(`modal.sure.${res}`);
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close') },
                      on: {
                        click: () => {
                          this.$modals.close();
                          // this.$store.dispatch(
                          //   'moduleStack/fetchOpenStackRequestFirewalls',
                          //   'firewalls'
                          // );
                          this.$router
                            .push({
                              name: 'RuleServers',
                              params: {
                                balancerid: this.$route.params.balancerid,
                                id: this.$route.params.id,
                                ruleid: this.rule,
                              },
                            })
                            .catch(() => {});
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    askSure(action) {
      // console.log(action.action.member);
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let mode = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        // text: this.$t('modal.sure.text', { msg: this.$t(`actions.${action.label}`) }),
        text: this.$t(`modal.sure.${action.handler}`, {
          msg: this.nameServer,
          pool: this.nameRule,
        }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                const payload = {
                  member: action.action.member,
                  ruleId: this.rule,
                  memberId: this.tariff.id,
                };
                // console.log(payload);
                this.$store
                  .dispatch('moduleStack/updateRuleMember', payload)
                  .then(data => {
                    // if (data && data.weight) {
                    this.isProcessing = false;
                    mode = action.key;
                    // console.log(data);
                    res = 'success';
                    // this.weight = data.weight;
                    // }
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${mode}`, {
                        msg: this.$t(`modal.sure.${mode}`),
                      });
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteCurrentMember(rule, member) {
      return this.$store
        .dispatch('moduleStack/deleteMember', {
          rule: rule,
          member: member,
        })
        .catch(e => this.showError(e));
    },
    async fetchMembers() {
      await this.$store
        .dispatch('moduleStack/getPoolMembers', { id: this.rule })
        .then(async data => {
          // console.log(data);
          this.member = data ? data : [];
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Вы уверены, что хотите удалить сервер: ",
    "actions": {
      "reserve": "Сделать резервным",
      "pause": "Приостановить",
      "unpause": "Возобновить",
      "unreserve": "Снять резерв",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "dhcp": "Включить|Выключить DHCP",
      "delete": "Удалить сервер"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь <b>%{msg}</b> сервер.<br /><br />  Вы уверены?",
        "delete": "Удалить сервер <b>%{msg}</b> из правила  <b>%{pool}</b>?",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "true": "включена",
        "false": "выключена",
        "unreserve": "Снять резерв с сервера <b>%{msg}</b>?",
        "reserve": "Сделать сервер <b>%{msg}</b> резервным?",
        "reserveT": "Сервер переведён в резервный режим.",
        "pause": "Для приостановки передачи запросов на сервер <b>%{msg}</b> будет изменён его вес на <b>0</b>. <br /><br />Для восстановления нажмите кнопку <b>\"Восстановить\"</b>, либо измените вес. <br/><br/>Приостановить передачу запросов?",
        "unpause": "Возобновить  передачу запросов на сервер <b>%{msg}</b>?",
        "pauseT": "Передача запросов приостановлена.",
        "unpauseT": "Передача запросов возобновлена.",
        "unreserveT": "Сервер переведён в обычный режим.",
        "success": "Сервер успешно удалён.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>
<!--//        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",-->

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
